<template>
  <div class="Role">
    <h2>角色管理</h2>
    <el-button
      type="primary"
      @click="handleAdd"
      :disabled="onCheckBtnAuthority('RoleAdd')"
      >添加</el-button
    >
    <div class="searchWrap">
      <span>企业</span>
      <el-select
        v-model="deptId"
        clearable
        size="small"
        placeholder="请选择"
        class="searchInput"
      >
        <el-option
          v-for="item in deptList"
          :key="item.id"
          :label="item.fullName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="primary" size="small" @click="onGetRoleList"
        >搜索</el-button
      >
    </div>
    <el-table :data="roleList" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <!-- <el-table-column prop="id" label="id"> </el-table-column> -->
      <el-table-column prop="fullName" label="企业"> </el-table-column>
      <el-table-column prop="roleName" label="角色名"> </el-table-column>
      <el-table-column prop="roleAlias" label="别名"> </el-table-column>
      <el-table-column label="操作" align="right" width="260">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEditBase(scope.$index, scope.row)"
            :disabled="scope.row.id == 0 || onCheckBtnAuthority('RoleEdit')"
            >基础信息</el-button
          >
          <el-button
            size="mini"
            @click="onGetRoleMenuData(scope.row)"
            :disabled="onCheckBtnAuthority('RoleAuthority')"
            >权限管理</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)"
            :disabled="scope.row.id == 0 || onCheckBtnAuthority('RoleDel')"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="权限管理"
      :visible.sync="showDialog"
      width="40%"
      :before-close="handleClose"
    >
      <el-tree
        ref="tree"
        :data="menuList"
        :props="defaultProps"
        :default-checked-keys="roleMenuList"
        show-checkbox
        node-key="id"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="基本信息"
      :visible.sync="showDialogBase"
      width="40%"
      :before-close="handleCloseBase"
    >
      <el-form
        :model="roleData"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
      >
        <el-form-item label="企业" prop="roleName">
          <el-select
            v-model="roleData.deptId"
            clearable
            placeholder="请选择"
            style="width: 100%;"
          >
            <el-option
              v-for="item in deptList"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色名" prop="roleName">
          <el-input v-model="roleData.roleName"></el-input>
        </el-form-item>
        <el-form-item label="别名" prop="roleAlias">
          <el-input v-model="roleData.roleAlias"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseBase">取 消</el-button>
        <el-button type="primary" @click="handleSubmitBase">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.Role {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "Role",
  components: {},
  data() {
    return {
      deptId: null,
      pageBtn: [],
      roleList: null,
      menuList: null,
      roleMenuList: null,
      showDialog: false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      roleIds: null,
      roleData: {
        id: null,
        deptId: null,
        roleName: null,
        roleAlias: null,
      },
      rules: {
        deptId: [{ required: true, message: "必填项", trigger: "blur" }],
        roleName: [{ required: true, message: "必填项", trigger: "blur" }],
        roleAlias: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      deptList: [],
      showDialogBase: false,
    };
  },
  computed: {
    checkedNodes: function () {
      var list = this.$refs.tree.getCheckedNodes(true);
      var arr = [];
      list.forEach((item) => {
        arr.push(item.id);
      });
      return arr;
    },
  },
  watch: {
    deptId: function () {
      this.onGetRoleList()
    }
  },
  mounted() {
    this.onGetPageBtn();
    this.onGetRoleList();
    this.onGetMenuList();
    this.onGetDeptListData();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1678967230820827137",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取角色列表
    onGetRoleList: function () {
      var that = this;
      var data = {
        deptId: this.deptId
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/role/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.roleList = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取菜单列表
    onGetMenuList: function () {
      var that = this;
      var data = {};
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/menu/tree",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.menuList = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取角色菜单数据
    onGetRoleMenuData: function (row) {
      var that = this;
      that.roleIds = row.id;
      var data = {
        id: row.id,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/menu/role-tree-keys",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.code == 200 && res.success == true) {
            that.roleMenuList = res.data;
            that.showDialog = true;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭
    handleClose: function () {
      this.roleMenuList = null;
      this.$refs.tree.setCheckedNodes([]);
      this.roleIds = null;
      this.showDialog = false;
    },
    // 提交
    handleSubmit: function () {
      this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onSubmit();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行提交
    onSubmit: function () {
      var that = this;
      var data = {
        roleIds: [this.roleIds],
        menuIds: this.checkedNodes,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/role/grant",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功!",
            });
            if (localStorage.getItem("roleId") == that.roleIds) {
              that.reloadUserMenuData();
            }
            that.handleClose();
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重新加载当前用户系统菜单
    reloadUserMenuData: function () {
      var that = this;
      var data = {};
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/menu/routes",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.code == 200 && res.success == true) {
            localStorage.setItem("menuData", JSON.stringify(res.data));
            location.reload();
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取组织机构列表
    onGetDeptListData: function () {
      var that = this;
      var data = {};
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dept/getDept",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.code == 200 && res.success == true) {
            that.deptList = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择部门
    handleChange: function (value) {
      this.roleData.deptId = value[value.length - 1];
    },

    // 添加角色
    handleAdd: function () {
      this.roleData.id = null;
      this.roleData.deptId = null;
      this.roleData.roleName = null;
      this.roleData.roleAlias = null;
      this.showDialogBase = true;
    },
    // 关闭基本信息窗口
    handleCloseBase: function () {
      this.roleData.id = null;
      this.roleData.deptId = null;
      this.roleData.roleName = null;
      this.roleData.roleAlias = null;
      this.showDialogBase = false;
      this.$refs['ruleForm'].resetFields();
    },
    // 编辑基本信息窗口
    handleEditBase: function (index, row) {
      console.log(index, row);
      this.roleData.id = row.id;
      this.roleData.deptId = row.deptId;
      this.roleData.roleName = row.roleName;
      this.roleData.roleAlias = row.roleAlias;
      this.showDialogBase = true;
    },
    // 提交基本信息
    handleSubmitBase: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmitBase();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 执行提交基本信息
    onSubmitBase: function () {
      var that = this;
      var data = this.roleData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/role/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.handleCloseBase();
            that.onGetRoleList();
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除
    handleDelete: function (row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDelete(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行删除
    onDelete: function (id) {
      var that = this;
      var data = {
        id: id,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/role/remove",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.onGetRoleList();
            that.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    